export const tokens = [
    {
        id: 0,
        name: "ChainLink Token",
        type: "LINK",
        shift: 0.82,
        unit: "vol",
        price: 11400,
        image: "/svgs/chainLink.svg",
        volume: 9000000,
        tvl: 900000,
    },
    {
        id: 1,
        name: "Wrapped BTC",
        type: "WBTC",
        shift: -1.58,
        unit: "vol",
        price: 1,
        image: "/svgs/wrappedBtc.svg",
        volume: 9000000,
        tvl: 9000600,
    },
    {
        id: 2,
        name: "USD Coin",
        type: "USDC",
        shift: -0.06,
        unit: "vol",
        price: 1,
        image: "/svgs/usdCoin.svg",
        volume: 9000000,
        tvl: 1000000,
    },
    {
        id: 3,
        name: "Tether",
        type: "USDT",
        shift: -0.05,
        unit: "vol",
        price: 11400,
        image: "/svgs/tether.svg",
        volume: 9000000,
        tvl: 90000,
    },
    {
        id: 4,
        name: "Ether",
        type: "ETH",
        shift: -0.93,
        unit: "vol",
        price: 11400,
        image: "/svgs/ether.svg",
        volume: 9000000,
        tvl: 9005500,
    },
    {
        id: 5,
        name: "Uniswap",
        type: "UNI",
        shift: 0.82,
        unit: "vol",
        price: 11400,
        image: "/svgs/uniswap.svg",
        volume: 9000000,
        tvl: 9000,
    },
    {
        id: 6,
        name: "StakeWise Staked ET...",
        type: "sETH2",
        shift: 1.84,
        unit: "vol",
        price: 11400,
        image: "/svgs/stackWise.svg",
        volume: 9000000,
        tvl: 9001240,
    },
    {
        id: 7,
        name: "USD Coin",
        type: "USDC",
        shift: -0.06,
        unit: "vol",
        price: 1,
        image: "/svgs/usdCoin.svg",
        volume: 9000000,
        tvl: 340000,
    },
    {
        id: 8,
        name: "Tether",
        type: "USDT",
        shift: -0.05,
        unit: "vol",
        price: 11400,
        image: "/svgs/tether.svg",
        volume: 9000000,
        tvl: 900400,
    },
    {
        id: 9,
        name: "Ether",
        type: "ETH",
        shift: -0.93,
        unit: "vol",
        price: 11400,
        image: "/svgs/ether.svg",
        volume: 9000000,
        tvl: 900060,
    },
    {
        id: 10,
        name: "Uniswap",
        type: "UNI",
        shift: 0.82,
        unit: "vol",
        price: 11400,
        image: "/svgs/uniswap.svg",
        volume: 9000000,
        tvl: 200000,
    },
    {
        id: 11,
        name: "StakeWise Staked ET...",
        type: "sETH2",
        shift: 1.84,
        unit: "vol",
        price: 11400,
        image: "/svgs/stackWise.svg",
        volume: 9000000,
        tvl: 200000,
    },
    {
        id: 12,
        name: "Uniswap",
        type: "UNI",
        shift: 0.82,
        unit: "vol",
        price: 11400,
        image: "/svgs/uniswap.svg",
        volume: 9000000,
        tvl: 957000,
    },
    {
        id: 13,
        name: "StakeWise Staked ET...",
        type: "sETH2",
        shift: 1.84,
        unit: "vol",
        price: 11400,
        image: "/svgs/stackWise.svg",
        volume: 9000000,
        tvl: 9036500,
    },
    {
        id: 14,
        name: "USD Coin",
        type: "USDC",
        shift: -0.06,
        unit: "vol",
        price: 1,
        image: "/svgs/usdCoin.svg",
        volume: 9000000,
        tvl: 500400,
    },
];

export const pools: {
    id: number;
    firstToken: {
        id: number;
        type: string;
        image: string;
    };
    secondToken: {
        id: number;
        type: string;
        image: string;
    };
    tvl: number;
    volume: {
        twentyFourHour: number;
        sevenDay: number;
    };
}[]
    = [
        {
            "id": 0,
            "firstToken": {
                "id": 12,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 9,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "tvl": 9588882,
            "volume": {
                "twentyFourHour": 4219521,
                "sevenDay": 4578226
            }
        },
        {
            "id": 1,
            "firstToken": {
                "id": 14,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 14,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "tvl": 3801867,
            "volume": {
                "twentyFourHour": 2962511,
                "sevenDay": 9207712
            }
        },
        {
            "id": 2,
            "firstToken": {
                "id": 12,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 13,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "tvl": 10804377,
            "volume": {
                "twentyFourHour": 8924584,
                "sevenDay": 8174780
            }
        },
        {
            "id": 3,
            "firstToken": {
                "id": 8,
                "type": "USDT",
                "image": "/svgs/tether.svg"
            },
            "secondToken": {
                "id": 9,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "tvl": 5395892,
            "volume": {
                "twentyFourHour": 8146939,
                "sevenDay": 9462506
            }
        },
        {
            "id": 4,
            "firstToken": {
                "id": 5,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 2,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "tvl": 9801687,
            "volume": {
                "twentyFourHour": 5100121,
                "sevenDay": 3131447
            }
        },
        {
            "id": 5,
            "firstToken": {
                "id": 13,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "secondToken": {
                "id": 4,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "tvl": 4429319,
            "volume": {
                "twentyFourHour": 2189645,
                "sevenDay": 1028621
            }
        },
        {
            "id": 6,
            "firstToken": {
                "id": 2,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 11,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "tvl": 10808358,
            "volume": {
                "twentyFourHour": 1336116,
                "sevenDay": 2555328
            }
        },
        {
            "id": 7,
            "firstToken": {
                "id": 5,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 6,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "tvl": 4382179,
            "volume": {
                "twentyFourHour": 7838278,
                "sevenDay": 4119393
            }
        },
        {
            "id": 8,
            "firstToken": {
                "id": 6,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "secondToken": {
                "id": 11,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "tvl": 9612148,
            "volume": {
                "twentyFourHour": 10811706,
                "sevenDay": 5718944
            }
        },
        {
            "id": 9,
            "firstToken": {
                "id": 5,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 9,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "tvl": 9313911,
            "volume": {
                "twentyFourHour": 10415778,
                "sevenDay": 9675708
            }
        },
        {
            "id": 10,
            "firstToken": {
                "id": 1,
                "type": "WBTC",
                "image": "/svgs/wrappedBtc.svg"
            },
            "secondToken": {
                "id": 5,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 9434302,
            "volume": {
                "twentyFourHour": 6794138,
                "sevenDay": 4999722
            }
        },
        {
            "id": 11,
            "firstToken": {
                "id": 13,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "secondToken": {
                "id": 8,
                "type": "USDT",
                "image": "/svgs/tether.svg"
            },
            "tvl": 1653711,
            "volume": {
                "twentyFourHour": 6871761,
                "sevenDay": 1408641
            }
        },
        {
            "id": 12,
            "firstToken": {
                "id": 14,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 1,
                "type": "WBTC",
                "image": "/svgs/wrappedBtc.svg"
            },
            "tvl": 5360703,
            "volume": {
                "twentyFourHour": 10852036,
                "sevenDay": 6316259
            }
        },
        {
            "id": 13,
            "firstToken": {
                "id": 0,
                "type": "LINK",
                "image": "/svgs/chainLink.svg"
            },
            "secondToken": {
                "id": 7,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "tvl": 7942274,
            "volume": {
                "twentyFourHour": 9370304,
                "sevenDay": 5314514
            }
        },
        {
            "id": 14,
            "firstToken": {
                "id": 2,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 12,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 8403224,
            "volume": {
                "twentyFourHour": 10304966,
                "sevenDay": 4199239
            }
        },
        {
            "id": 15,
            "firstToken": {
                "id": 3,
                "type": "USDT",
                "image": "/svgs/tether.svg"
            },
            "secondToken": {
                "id": 12,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 5013817,
            "volume": {
                "twentyFourHour": 2823184,
                "sevenDay": 10949061
            }
        },
        {
            "id": 16,
            "firstToken": {
                "id": 7,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 7,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "tvl": 6200310,
            "volume": {
                "twentyFourHour": 1180648,
                "sevenDay": 6838801
            }
        },
        {
            "id": 17,
            "firstToken": {
                "id": 6,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "secondToken": {
                "id": 2,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "tvl": 3397014,
            "volume": {
                "twentyFourHour": 8495291,
                "sevenDay": 10373224
            }
        },
        {
            "id": 18,
            "firstToken": {
                "id": 11,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "secondToken": {
                "id": 1,
                "type": "WBTC",
                "image": "/svgs/wrappedBtc.svg"
            },
            "tvl": 1431442,
            "volume": {
                "twentyFourHour": 2381435,
                "sevenDay": 2930858
            }
        },
        {
            "id": 19,
            "firstToken": {
                "id": 13,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "secondToken": {
                "id": 0,
                "type": "LINK",
                "image": "/svgs/chainLink.svg"
            },
            "tvl": 7610182,
            "volume": {
                "twentyFourHour": 8588914,
                "sevenDay": 9133294
            }
        },
        {
            "id": 0,
            "firstToken": {
                "id": 13,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "secondToken": {
                "id": 14,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "tvl": 3781570,
            "volume": {
                "twentyFourHour": 4679244,
                "sevenDay": 4757107
            }
        },
        {
            "id": 1,
            "firstToken": {
                "id": 14,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 1,
                "type": "WBTC",
                "image": "/svgs/wrappedBtc.svg"
            },
            "tvl": 5961243,
            "volume": {
                "twentyFourHour": 10558425,
                "sevenDay": 4487013
            }
        },
        {
            "id": 2,
            "firstToken": {
                "id": 7,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 12,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 6662313,
            "volume": {
                "twentyFourHour": 9208797,
                "sevenDay": 7980689
            }
        },
        {
            "id": 3,
            "firstToken": {
                "id": 2,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 3,
                "type": "USDT",
                "image": "/svgs/tether.svg"
            },
            "tvl": 10878137,
            "volume": {
                "twentyFourHour": 2459052,
                "sevenDay": 1882051
            }
        },
        {
            "id": 4,
            "firstToken": {
                "id": 13,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "secondToken": {
                "id": 4,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "tvl": 9971446,
            "volume": {
                "twentyFourHour": 8525878,
                "sevenDay": 3735954
            }
        },
        {
            "id": 5,
            "firstToken": {
                "id": 6,
                "type": "sETH2",
                "image": "/svgs/stackWise.svg"
            },
            "secondToken": {
                "id": 0,
                "type": "LINK",
                "image": "/svgs/chainLink.svg"
            },
            "tvl": 8008791,
            "volume": {
                "twentyFourHour": 3338697,
                "sevenDay": 3878396
            }
        },
        {
            "id": 6,
            "firstToken": {
                "id": 10,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 8,
                "type": "USDT",
                "image": "/svgs/tether.svg"
            },
            "tvl": 3731659,
            "volume": {
                "twentyFourHour": 5402264,
                "sevenDay": 3143998
            }
        },
        {
            "id": 7,
            "firstToken": {
                "id": 5,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 12,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 10597577,
            "volume": {
                "twentyFourHour": 4394385,
                "sevenDay": 2937239
            }
        },
        {
            "id": 8,
            "firstToken": {
                "id": 12,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 5,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 10644935,
            "volume": {
                "twentyFourHour": 5869253,
                "sevenDay": 8357392
            }
        },
        {
            "id": 9,
            "firstToken": {
                "id": 10,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 8,
                "type": "USDT",
                "image": "/svgs/tether.svg"
            },
            "tvl": 8986994,
            "volume": {
                "twentyFourHour": 9062372,
                "sevenDay": 4741303
            }
        },
        {
            "id": 10,
            "firstToken": {
                "id": 5,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 10,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 10468572,
            "volume": {
                "twentyFourHour": 9453450,
                "sevenDay": 6598440
            }
        },
        {
            "id": 11,
            "firstToken": {
                "id": 12,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 4,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "tvl": 8481903,
            "volume": {
                "twentyFourHour": 7951448,
                "sevenDay": 2974908
            }
        },
        {
            "id": 12,
            "firstToken": {
                "id": 8,
                "type": "USDT",
                "image": "/svgs/tether.svg"
            },
            "secondToken": {
                "id": 1,
                "type": "WBTC",
                "image": "/svgs/wrappedBtc.svg"
            },
            "tvl": 7742183,
            "volume": {
                "twentyFourHour": 6068626,
                "sevenDay": 2478016
            }
        },
        {
            "id": 13,
            "firstToken": {
                "id": 14,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 9,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "tvl": 2613383,
            "volume": {
                "twentyFourHour": 10874844,
                "sevenDay": 9315078
            }
        },
        {
            "id": 14,
            "firstToken": {
                "id": 10,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 3,
                "type": "USDT",
                "image": "/svgs/tether.svg"
            },
            "tvl": 7297986,
            "volume": {
                "twentyFourHour": 9699712,
                "sevenDay": 6373560
            }
        },
        {
            "id": 15,
            "firstToken": {
                "id": 0,
                "type": "LINK",
                "image": "/svgs/chainLink.svg"
            },
            "secondToken": {
                "id": 5,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 2241585,
            "volume": {
                "twentyFourHour": 2837921,
                "sevenDay": 6224712
            }
        },
        {
            "id": 16,
            "firstToken": {
                "id": 9,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "secondToken": {
                "id": 4,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "tvl": 4704480,
            "volume": {
                "twentyFourHour": 8278637,
                "sevenDay": 10716196
            }
        },
        {
            "id": 17,
            "firstToken": {
                "id": 10,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "secondToken": {
                "id": 7,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "tvl": 2686515,
            "volume": {
                "twentyFourHour": 10353787,
                "sevenDay": 3333099
            }
        },
        {
            "id": 18,
            "firstToken": {
                "id": 4,
                "type": "ETH",
                "image": "/svgs/ether.svg"
            },
            "secondToken": {
                "id": 10,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 7689735,
            "volume": {
                "twentyFourHour": 5568782,
                "sevenDay": 6585559
            }
        },
        {
            "id": 19,
            "firstToken": {
                "id": 7,
                "type": "USDC",
                "image": "/svgs/usdCoin.svg"
            },
            "secondToken": {
                "id": 12,
                "type": "UNI",
                "image": "/svgs/uniswap.svg"
            },
            "tvl": 3701416,
            "volume": {
                "twentyFourHour": 8613862,
                "sevenDay": 4141599
            }
        }
    ]




export const poolTransactionData = [
    {
        transaction: "Swap USDC for ETH",
        totalValue: 500,
        tokenAmount: 2,
        account: "0x1234567890",
        time: 1616338400, // Unix timestamp in seconds
    },
    {
        transaction: "Add liquidity",
        totalValue: 1000,
        tokenAmount: 5,
        account: "0x0987654321",
        time: 1626345600,
    },
    {
        transaction: "Remove liquidity",
        totalValue: 750,
        tokenAmount: 3,
        account: "0xabcdef1234",
        time: 1636352800,
    },
    {
        transaction: "Swap DAI for USDC",
        totalValue: 800,
        tokenAmount: 4,
        account: "0x4567890123",
        time: 1646360000,
    },
    {
        transaction: "Add liquidity",
        totalValue: 1200,
        tokenAmount: 6,
        account: "0x6543210987",
        time: 1656367200,
    },
    {
        transaction: "Remove liquidity",
        totalValue: 900,
        tokenAmount: 3.5,
        account: "0xcba9876543",
        time: 1666374400,
    },
    {
        transaction: "Swap ETH for USDT",
        totalValue: 600,
        tokenAmount: 1.5,
        account: "0x7890123456",
        time: 1627381600,
    },
    {
        transaction: "Add liquidity",
        totalValue: 1500,
        tokenAmount: 7.5,
        account: "0x9876543210",
        time: 1628388800,
    },
    {
        transaction: "Remove liquidity",
        totalValue: 1100,
        tokenAmount: 4.2,
        account: "0xdcba543210",
        time: 1629396000,
    },
    {
        transaction: "Swap BTC for ETH",
        totalValue: 9000,
        tokenAmount: 3.5,
        account: "0x0123456789",
        time: 1606403200,
    },
    // Add 10 more dummy transactions here
];
