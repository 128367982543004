import Image from "next/image";
import Link from "next/link";
import React, { useRef, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "@/redux/common/commonSlice";
import { useOnClickOutside } from "@/utils/clickOutside";

export default function NavBar() {
  const [showChainDropdown, setShowChainDropdown] = React.useState(false);
  const [showMobileDropDown, setShowMobileDropDown] = React.useState(false);
  const router = useRouter();

  const [activeChain, setActiveChain] = useState("StarkNet");

  const dispatch = useDispatch();

  const searchQuery = useSelector(
    (state: { common: { searchQuery: string } }) => state.common.searchQuery
  );

  return (
    <>
      <div className="fixed inset-x-0 top-0 isolate z-[60] flex h-[50px] w-full items-center justify-center overflow-hidden bg-amber-600 px-6 text-center font-medium text-white">
        <p>TEST DATA!!! TEST DATA!!! TEST DATA!!!</p>
      </div>
      <header className="fixed inset-0 top-[50px] z-[60] flex h-[60px] w-full items-center justify-between gap-4 bg-black px-4 py-4 lg:h-[65px]">
        <div className="flex w-[calc(100%_-_50px)] items-center gap-4 lg:w-[calc(100%_-_600px)] lg:justify-start">
          <Link href="/">
            <Image
              src="/logo.svg"
              alt="StarkDefi AMM analytics"
              width={45}
              height={45}
              className="object-contain"
            />
          </Link>
          <form className="relative mx-auto flex w-full justify-center lg:w-full lg:justify-start">
            <div className="relative mx-auto flex w-full justify-center ">
              <input
                type="text"
                placeholder="Search"
                id="search"
                name="search"
                value={searchQuery}
                onChange={(e) => {
                  dispatch(setSearchQuery(e.target.value));
                }}
                className="search-input h-[40px] w-full max-w-[200px] pl-16 text-white sm:max-w-[unset] lg:h-[50px]"
              />
              <Image
                src="/svgs/search.svg"
                height={20}
                width={20}
                alt="Search"
                className="absolute md:left-4 left-12 top-[50%] my-auto h-6 w-6 -translate-y-1/2 text-white/50"
              />
            </div>
          </form>
        </div>

        <div className="hidden w-[600px] items-center gap-4 lg:flex">
          <div className="flex h-[50px] items-center gap-3 rounded-md bg-[#1B1B1B] px-2">
            <OneTab
              text="Overview"
              link="/"
              isActive={router?.pathname === "/"}
            />
            <OneTab
              text="Tokens"
              link="/tokens"
              isActive={router?.pathname?.startsWith("/tokens")}
            />
            <OneTab
              text="Pools"
              link="/pools"
              isActive={router?.pathname?.startsWith("/pools")}
            />
          </div>
          <div
            className="w-[150px] cursor-pointer"
            onClick={() => setShowChainDropdown(!showChainDropdown)}
          >
            <div className="flex h-[50px] items-center justify-start gap-4 rounded-[25px] border-[1px] border-white/[0.13] bg-[#1B1B1B] px-[0.18rem]">
              <Image
                src="/svgs/chainOne.svg"
                alt="Chain One - active"
                className="rounded-3xl object-contain"
                height={45}
                width={45}
              />
              <span className="text-sm font-semibold text-white">StarkNet</span>
            </div>
          </div>
          <a
            href={process.env.NEXT_PUBLIC_HOST}
            target="_blank"
            rel="noreferrer"
            className="flex h-[50px] w-[100px] items-center justify-center rounded-md border-[1px] border-white/[0.13] bg-[#1B1B1B] text-sm font-semibold text-white"
          >
            Dapp
          </a>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-align-right h-9 w-9 text-[#616161] lg:hidden"
          onClick={() => setShowMobileDropDown(!showMobileDropDown)}
        >
          <line x1="21" x2="3" y1="6" y2="6" />
          <line x1="21" x2="9" y1="12" y2="12" />
          <line x1="21" x2="7" y1="18" y2="18" />
        </svg>

        <AnimatePresence>
          {showChainDropdown && (
            <ChainDropdown
              activeChain={activeChain}
              setActiveChain={setActiveChain}
              selectChain={() => setShowChainDropdown(false)}
              close={() => {
                setShowChainDropdown(false);
              }}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showMobileDropDown && (
            <MobileDropdown
              setShowChainDropdown={(val) => {
                setShowMobileDropDown(false);
                setShowChainDropdown(val);
              }}
              close={() => {
                setShowMobileDropDown(false);
              }}
            />
          )}
        </AnimatePresence>
      </header>
    </>
  );
}

function OneTab({
  link,
  isActive,
  text,
}: {
  link: string;
  isActive?: boolean;
  text: string;
}) {
  return (
    <Link
      href={link}
      className={`${
        isActive
          ? "border-green font-medium text-green hover:bg-green/20"
          : "border-white/[0.13] font-normal text-white hover:bg-green/20"
      } flex h-max w-full items-center justify-center rounded-md border-[1px] px-4 py-2 text-sm transition-all duration-300 ease-in`}
    >
      {text}
    </Link>
  );
}

function ChainDropdown({
  setActiveChain,
  activeChain,
  close,
}: {
  selectChain: (chain: string) => void;
  setActiveChain: (chain: string) => void;
  activeChain: string;
  close: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => close());
  return (
    <motion.div
      initial={{ y: 10 }}
      animate={{ y: 0 }}
      exit={{ y: 10, opacity: 0 }}
      ref={ref}
      transition={{ duration: 0.3, ease: "easeIn" }}
      className="absolute right-4 top-24 flex w-[225px] flex-col gap-3 rounded-md border-[1px] border-white/[0.13] bg-[#1B1B1B] py-2 md:right-20 md:top-[4.5rem]"
    >
      <svg
        width="2"
        height="74"
        viewBox="0 0 2 74"
        fill="none"
        className="absolute inset-y-0 left-[1.45rem] h-full"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.27612 73.9863L1.27612 38.432"
          stroke="url(#paint0_linear_272_1011)"
          stroke-dasharray="2 2"
        />
        <path
          d="M1.276 34.4316L1.276 0.365233"
          stroke="url(#paint1_linear_272_1011)"
          stroke-dasharray="2 2"
        />
        <defs>
          <linearGradient
            id="paint0_linear_272_1011"
            x1="0.776123"
            y1="73.9863"
            x2="0.776125"
            y2="38.432"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_272_1011"
            x1="0.776001"
            y1="34.4316"
            x2="0.776002"
            y2="0.365234"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <OneChainItem
        isActive={activeChain === "StarkNet"}
        selectChain={() => setActiveChain("StarkNet")}
        text="StarkNet"
        image="/svgs/chainOne.svg"
      />
      <OneChainItem
        isActive={activeChain === "Zksync"}
        selectChain={() => setActiveChain("Zksync")}
        text="Zksync"
        image="/svgs/two.svg"
      />
      <OneChainItem
        selectChain={() => setActiveChain("Polygon ZkEVM")}
        text="Polygon ZkEVM"
        isActive={activeChain === "Polygon ZkEVM"}
        image="/svgs/three.svg"
      />
    </motion.div>
  );
}

function OneChainItem({
  isActive,
  selectChain,
  selector,
  text,
  image,
}: {
  isActive?: boolean;
  selectChain: () => void;
  selector?: boolean;
  text: string;
  image: string;
}) {
  return (
    <div
      onClick={() => selectChain()}
      className={`flex w-full cursor-pointer items-center gap-4 py-3 pl-12 transition-all duration-300 ease-in last:pb-4 md:py-2 md:last:pb-2 md:hover:bg-white/[0.13] ${
        selector ? "px-0" : "px-4"
      }`}
    >
      <div className="absolute left-2 flex h-8 w-8 items-center justify-center">
        {isActive ? (
          <Image
            src={image}
            alt="Chain One - active"
            className="rounded-3xl object-contain"
            height={30}
            width={30}
          />
        ) : (
          <div className="m-auto h-3 w-3 rounded-full bg-gray-300" />
        )}
      </div>

      <span
        className={`text-sm font-semibold ${
          isActive ? "text-green" : "text-white"
        }`}
      >
        {text}
      </span>
    </div>
  );
}

function MobileDropdown({
  setShowChainDropdown,
  close,
}: {
  setShowChainDropdown: (show: boolean) => void;
  close: () => void;
}) {
  const router = useRouter();

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => close());

  return (
    <motion.div
      initial={{ y: 10 }}
      animate={{ y: 0 }}
      exit={{ y: 10, opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeIn" }}
      ref={ref}
      className="fixed right-4 top-[9rem] flex h-max w-auto min-w-[300px] max-w-[400px] flex-col gap-4 rounded-md border-[1px] border-white/[0.13] bg-[#1B1B1B] p-4 text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        onClick={() => close()}
        stroke="currentColor"
        className="absolute -right-1 -top-10 h-7 w-7 text-[#616161]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
      <div
        className="w-full cursor-pointer"
        onClick={() => setShowChainDropdown(true)}
      >
        <div className="flex h-[50px] items-center justify-start gap-4 rounded-[25px] border-[1px] border-white/[0.13] bg-[#1B1B1B] px-[0.18rem]">
          <Image
            src="/svgs/chainOne.svg"
            alt="Chain One - active"
            className="rounded-3xl object-contain"
            height={45}
            width={45}
          />
          <span className="text-sm font-semibold text-white">StarkNet</span>
        </div>
      </div>
      <OneTab text="Overview" link="/" isActive={router?.pathname === "/"} />
      <OneTab
        text="Tokens"
        link="/tokens"
        isActive={router?.pathname?.startsWith("/tokens")}
      />
      <OneTab
        text="Pools"
        link="/pools"
        isActive={router?.pathname?.startsWith("/pools")}
      />

      <a
        href={
          process.env.VERCEL_ENV === "production"
            ? "https://app.starkdefi.com"
            : "http://testnet.starkdefi.com"
        }
        target="_blank"
        rel="noreferrer"
        className="flex h-[50px] w-full items-center justify-center gap-4 rounded-md border-[1px] border-white/[0.13] bg-[#1B1B1B] text-sm font-semibold text-white"
      >
        Dapp
      </a>
    </motion.div>
  );
}
